import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/8o678GhbLwM">
    <SEO title="5 Keys to Winning Relationships - Relationships" />
  </Layout>
)

export default SermonPost
